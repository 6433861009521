<script>

</script>

<template>
  <Layout>
    <H2>FORGOT PASSWORD</H2>
  </Layout>
</template>

<style lang="scss" module></style>
